<template>
  <div class="card">
    <div class="card-title vertical-center">
      笔记简介
    </div>
    <div class="card-info">{{ info.brief}}</div>
    <div class="uptext">笔记截图</div>
    <img class="card-info-img" alt="" :src="info.icon" />
  </div>
</template>

<script>
export default {
  name: "cardinfo",
  data() {
    return {
      starnum:3,
      value:'',
      checked:false,
      info:{}
    }
  },
  created() {
    this.getinfo()
  },
  mounted() {

  },
  methods: {
    getinfo(){
      let url = this.$api.punchClock +'/'+ this.$route.query.id ;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
          this.info = res.data.data
        }
      })
    }
  },
  watch: {
    userInfo(val, oldVal) {
      return val;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  components: {
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/group/card.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;

</style>